import React from "react"
import ProjectLayout from "../../layouts/project"


export default () => {
    const projectId = 'wander_with_wonder';

    return (
        <ProjectLayout>
            <React.Fragment>
                {projectId}
            </React.Fragment>
        </ProjectLayout>
    )
}

